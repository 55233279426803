// import axios from 'axios';

// // Crea una instancia de Axios con la configuración deseada
// const instance = axios.create({
//   baseURL: process.env.VUE_APP_API_URL, 
// });

// instance.defaults.withCredentials = true
// instance.defaults.withXSRFToken = true

// export default instance;


// import axios from 'axios';

// // Crea una instancia de Axios con la configuración deseada
// const instance = axios.create({
//   baseURL: process.env.VUE_APP_API_URL,
//   withCredentials: true,
// });

// // Interceptor para agregar el token de autorización a cada solicitud
// instance.interceptors.request.use(config => {
//   const token = localStorage.getItem('auth_token'); // Obtener el token del localStorage
//   if (token) {
//     config.headers['Authorization'] = `Bearer ${token}`; // Agregar el token a las cabeceras
//   }
//   return config;
// }, error => {
//   return Promise.reject(error);
// });

// export default instance;

import axios from 'axios';
import Cookies from 'js-cookie'; // Usamos js-cookie para leer las cookies

// Crea una instancia de Axios con la configuración deseada
const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: true,  // Asegura que se envíen las cookies de sesión y CSRF
});

// Interceptor para agregar el token de autorización y el token CSRF a cada solicitud
instance.interceptors.request.use(config => {
  const token = localStorage.getItem('auth_token'); // Obtener el token de autorización del localStorage
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;  // Agregar el token de autorización
  }

  // Leer la cookie XSRF-TOKEN y agregarla a las cabeceras
  const xsrfToken = Cookies.get('XSRF-TOKEN'); // Obtener el token CSRF de la cookie
  if (xsrfToken) {
    config.headers['X-XSRF-TOKEN'] = xsrfToken;  // Agregar la cabecera X-XSRF-TOKEN
  }

  return config;
}, error => {
  return Promise.reject(error);
});

export default instance;

