// import instance from '@/axios-config';
// import { readResource } from '@/utils/api';
// import Vue from 'vue'
// import Vuex from 'vuex'

// Vue.use(Vuex)

// export default new Vuex.Store({
//   state: {
//     user: null,
//     permissions: [],
//     auth: false,
//     loaded: false,
//   },
//   mutations: {
//     SET_USER(state, res) {
//     state.user = res?.user;
//       state.permissions = res?.userSys.permissions
//       state.auth = Boolean(res?.user)
//       state.loaded = true
//     },
//     logout(state) {
//       state.user = null;
//       state.permissions = []
//     }
//   },
//   actions: {
//     async login(){
//       await instance.get('/sanctum/csrf-cookie')
//       const res = await readResource('/login/microsoft')
//       if(res.url){
//         window.location.href = res.url
//       }
//     },
//     getUser({commit}){
//       readResource('/users').then((res) => {
//         commit('SET_USER', res)
//       }).catch(() => {
//         commit('SET_USER', null)
//       })
//     },
//     async logout({dispatch}){
//       await readResource('/logout')
//       return dispatch('getUser')
//     },
//   },
//   getters: {
    
//   }
// });
import instance from '@/axios-config'; // Usamos la instancia de Axios directamente
import Vue from 'vue';
import Vuex from 'vuex';
import { readResource } from '@/utils/api';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: null,
    permissions: [],
    auth: false,
    loaded: false,
  },
  mutations: {
    SET_USER(state, res) {
      state.user = res?.user;
      state.permissions = res?.userSys?.permissions || [];
      state.auth = Boolean(res?.user);
      state.loaded = true;
    },
    LOGOUT(state) {
      state.user = null;
      state.permissions = [];
      state.auth = false;
      state.loaded = false;
    },
    setAuth(state, authStatus) {
      state.auth = authStatus; // Mutación para actualizar el estado de autenticación
    },
    setLoaded(state, value) {
      state.loaded = value; // Permite controlar manualmente el estado de carga
    },
  },
  actions: {
    async login() {
      // Aquí pedimos la cookie CSRF y redirigimos a Microsoft
      await instance.get('/sanctum/csrf-cookie');
      const res = await readResource('/login/microsoft') // Usamos Axios directamente
      if (res.url) {
        window.location.href = res.url; // Redirigir al login de Microsoft
      }
    },
    async getUser({ commit }) {
      try {
        const response = await instance.get(`${process.env.VUE_APP_API_PREFIX}/users`); // Usamos Axios directamente
        commit('SET_USER', response.data); // Almacenar los datos del usuario en el store
      } catch (error) {
        console.error('Error al obtener el usuario:', error);
        commit('SET_USER', null); // En caso de error, limpiar el usuario
      }
    },
    async logout({ commit }) {
      try {
        await instance.get('/sanctum/csrf-cookie');
        // Realiza la solicitud al backend para cerrar la sesión
        await instance.post(`${process.env.VUE_APP_API_PREFIX}/logout`);
        
        // Llama a la mutación logout
        commit('LOGOUT');
      } catch (error) {
        console.error('Error al cerrar sesión en el backend:', error);
        throw error;
      }
    },  
  },
  getters: {
    isLoggedIn: (state) => !!state.user, // Devuelve verdadero si hay un usuario logueado
  }
});
