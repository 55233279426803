<!-- <template>
  <div v-if="$store.state.auth">
    <AppLayout>
    <router-view/>
  </AppLayout>
  </div>
  <div v-else>
    <BlankLayout>
      <router-view/>
    </BlankLayout>
  </div>
</template>

<script>
import AppLayout from './layouts/App.vue';
import BlankLayout from './layouts/Blank.vue';

export default {
  name: 'App',

  components: {
    AppLayout,
    BlankLayout
  },

  data: () => ({

  }),

  watch: {
    // Observa cambios en la variable 'auth' del store
    '$store.state.auth': function(newVal) {
      if (!newVal) {
        // Si 'auth' cambia a falso, redirige al usuario a la página de inicio de sesión
        this.$router.push('/login');
      }
    }
  },

  mounted(){

  },
  methods: {

  }
}
</script> -->
<template>
  <div v-if="$store.state.auth">
    <AppLayout>
    <router-view/>
  </AppLayout>
  </div>
  <div v-else>
    <BlankLayout>
      <router-view/>
    </BlankLayout>
  </div>
</template>

<script>
import AppLayout from './layouts/App.vue';
import BlankLayout from './layouts/Blank.vue';

export default {

  components: {
    AppLayout,
    BlankLayout
  },

  mounted() {
    // Obtener los parámetros de la URL, incluyendo el token
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token'); // Capturamos el token de la URL

    if (token) {
      // Almacenar el token en localStorage
      localStorage.setItem('auth_token', token);

      // Llamar a la acción de Vuex para obtener los datos del usuario
      this.$store.dispatch('getUser').then(() => {
        // Verificar si los datos del usuario están correctamente cargados en Vuex
        if (this.$store.state.user) {
          // Redirigir al usuario a la página principal o donde corresponda
          this.$router.push('/');
        } else {
          // Si no se obtuvieron los datos del usuario, redirigir al login
          this.$router.push('/login');
        }
      }).catch((error) => {
        console.error('Error al obtener el usuarioo:', error);
        // En caso de error, redirigir al login
        this.$router.push('/login');
      });
    } else {
      // Si no hay token, redirigir al login
      this.$router.push('/login');
    }
  }
};
</script>
