import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)


const opts = {
    theme: {
        themes: {
            light: {
                primary: '#BE1522',
                secondary: '#6c757d',
                accent: '#FCF7F8',
                error: '#BE1823',
                info: '#083D77',
                success: '#1F850C',
                warning: '#EBBA2E',
                petrol: '#17a499',
                background: '#fff',
            }
        },
        options: {
            customProperties: true
        },
    },
}

export default new Vuetify(opts)