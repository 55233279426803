import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store/store'

Vue.config.productionTip = false

store.dispatch('getUser')

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
// Llamar a getUser y luego montar la aplicación

// const token = localStorage.getItem('auth_token');
// if (token) {
//   // Si hay un token, llamar a getUser antes de montar la app
//   store.dispatch('getUser').then(() => {
//     new Vue({
//       vuetify,
//       router,
//       store,
//       render: h => h(App)
//     }).$mount('#app')
//   }).catch(() => {
//     new Vue({
//       vuetify,
//       router,
//       store,
//       render: h => h(App)
//     }).$mount('#app')
//   });
// } else {
//   // Si no hay token, asegurar que el estado auth esté en false y montar la app
//   store.commit('setAuth', false); // Aseguramos que auth sea false
//   new Vue({
//     vuetify,
//     router,
//     store,
//     render: h => h(App)
//   }).$mount('#app')
// }