<template>
  <v-container style="margin:0; padding:0; max-width: 100%;">
    <slot/>
  </v-container>
</template>
  
<script>
export default {
  name:'BlankLayout',

  mounted() {
  },
  methods: {
  }
};
</script>